import { createHashHistory } from 'history'
import { configure } from 'mobx'
import { Provider } from 'mobx-react'
import { syncHistoryWithStore } from 'mobx-react-router'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Route, Router } from 'react-router-dom'
import './index.css'
import { routerStore } from './modules/common/stores/routerStore'

const trackingId = 'UA-150851767-1' // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId)
ReactGA.ga('set', 'anonymizeIp', true)

configure({
  enforceActions: 'always'
})

// import registerServiceWorker from './registerServiceWorker';
async function renderApp () {
  const browserHistory = createHashHistory()
  const history = syncHistoryWithStore(browserHistory, routerStore)

  history.listen(location => {
    ReactGA.set({ page: location.pathname }) // Update the user's current page
    ReactGA.pageview(location.pathname) // Record a pageview for the given page
  })

  const App = (await import('./App')).default
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
  window.addEventListener('resize', () => {
    // We execute the same script as before
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  })

  ReactDOM.render(
    <Provider >
      <Router history={ history }>
        <Route component={ App } props/>
      </Router>
    </Provider>,
    document.getElementById('root') as HTMLElement)
}
renderApp()
